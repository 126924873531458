import './Menu.scss'
import { Component } from 'react'
import { NavLink } from 'react-router-dom'
import avatar from '../Assets/avatar.png'
import { SocialIcon } from 'react-social-icons'
import _ from 'lodash'
import { MdDarkMode, MdLightMode } from "react-icons/md";
import { ThemeContext } from '../Context/ThemeContext';

import React from 'react';

class Menu extends Component {
  static contextType = ThemeContext;

  static items = [
    { path: "/", title: "Home", root: true, icon: null, iconClass: "icon-home" },
    { path: "/articles", title: "Articles", icon: null, iconClass: "icon-articles" },
    { path: "/projects", title: "Projects", icon: null, iconClass: "icon-projects" },
    { path: "/contact", title: "Contact", icon: null, iconClass: "icon-contact" }
  ]

  render() {
    const { theme, toggleTheme } = this.context;

    return (
      <div>
        <div className="avatar-container">
          <NavLink to="/" onClick={this.props.onClick}>
            <img src={avatar} alt="avatar" />
          </NavLink>
        </div>
        <ul className={"menu" + (this.props.burgerMenuOpened ? " burger-menu-opened" : "")}>
          {this.renderListItems(Menu.items)}
        </ul>
        <div className={"social" + (this.props.burgerMenuOpened ? " burger-menu-opened" : "")}>
          <SocialIcon url="https://github.com/billp" network="github" style={{ height: 20, width: 20 }} target='blank' /> &nbsp;
          <SocialIcon url="https://www.linkedin.com/in/billp-dev" style={{ height: 20, width: 20 }} target='blank'/> &nbsp;
          <SocialIcon url="https://www.facebook.com/bill.panagiotopoulos" network="facebook" style={{ height: 20, width: 20 }} target='blank' /> &nbsp;
        </div>
        <div className="change-theme">
          <a href onClick={toggleTheme}>
            {theme === 'light' ? <MdLightMode /> : <MdDarkMode /> }
          </a>
        </div>
      </div>
    )
  }

  renderListItems(items) {
    return _.map(items, (item) => {
      return (
        <li>
          <NavLink
            to={item.path}
            onClick={this.props.onClick}
            exact={item.root}
            activeClassName="selected">
            <span className={`menu-icon ${item.iconClass}`} >{item.icon}</span>
            {item.title}
          </NavLink>
        </li>
      )
    })
  }
}

export default Menu
