import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom'
import Layout from './Layout/Layout'
import React from 'react';

import {
  Home,
  Articles,
  Projects,
  Contact,
  NotFound
} from './Screens'

function App() {
  return (
    <Router>
      <Layout>
        <Switch>
          <Route path="/articles">
            <Articles />
          </Route>
          <Route path="/projects">
            <Projects />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
          <Route exact path="/">
            <Home />
          </Route>
          <Route component={NotFound} />
        </Switch>
      </Layout>
    </Router>
  );
}

export default App;
