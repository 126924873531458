import { Component } from 'react'
import React from 'react';
import ReactMarkdown from 'react-markdown/with-html'
import Markdown from './Contact.md'
import { Rings } from "react-loader-spinner";

class Contact extends Component {
  state = {
    markdown: ""
  }

  componentWillMount() {
    fetch(Markdown).then(res => res.text())
      .then(text => this.setState({ markdown: text }))
  }

  render() {
    return (
      <div className="content">
        {this.state.markdown ?
          <ReactMarkdown allowDangerousHtml>{this.state.markdown}</ReactMarkdown> :
          <Rings color="gray" width="30px" height="30px" />
        }
      </div>
    )
  }
}

export default Contact
