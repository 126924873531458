import './Layout.scss'
import { Component } from 'react';
import React from 'react';
import Menu from './Menu'
import { withRouter } from 'react-router-dom'
import { ReactComponent as MenuIcon } from '../Assets/menu.svg'
import { ReactComponent as CloseIcon } from '../Assets/x.svg'
import _ from 'lodash'
import { ThemeProvider } from '../Context/ThemeContext';

class Layout extends Component {
  state = {
    burgerMenuOpened: false
  }

  componentWillMount() {
    window.addEventListener('resize', this.resetBurgerMenuIfNeeded.bind(this))
  }

  render() {
    return (
      <ThemeProvider>
        <div id="main-background">
          <div id="main-container">
            <div className="hamburger-menu">
              {this.renderBurgerIcon()}
            </div>
            <div id="left-column">
              <Menu
                burgerMenuOpened={this.state.burgerMenuOpened}
                onClick={this.resetBurgerMenu.bind(this)} />
            </div>
            <div id="right-column" className={"main-content " + (this.state.burgerMenuOpened === true ? "burger-menu-opened" : "")}>
              <div className="navigation">
                {this.getCurrentRoute()}
              </div>
              {this.props.children}
              <footer>Vassilis Panagiotopoulos © 2024</footer>
            </div>
          </div>
        </div>
      </ThemeProvider>
    )
  }

  getCurrentRoute() {
    let pathComponents = _.split(window.location.pathname, '-')
    let rootPath = ""
    if (pathComponents[0] === "/") {
      rootPath = "Home"
    } else {
      rootPath = _.chain(pathComponents)
        .map(val => val.replace("/", ""))
        .first()
        .capitalize()
        .value()
    }
    return rootPath
  }

  burgerMenuToggle(e) {
    this.setState({ burgerMenuOpened: !this.state.burgerMenuOpened })
  }

  renderBurgerIcon(e) {
    if (this.state.burgerMenuOpened) {
      return <CloseIcon width="32px" onClick={this.burgerMenuToggle.bind(this)} />
    } else {
      return <MenuIcon width="32px" onClick={this.burgerMenuToggle.bind(this)} />
    }
  }

  resetBurgerMenuIfNeeded() {
    if (window.innerWidth > 700) {
      this.resetBurgerMenu()
    }
  }

  resetBurgerMenu() {
    this.setState({ burgerMenuOpened: false })
  }
}

export default withRouter(Layout)
